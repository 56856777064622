import * as d3 from 'd3';

import '../styles/index.scss';

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}

function adjustBodyHeight() {
  function onResize() {
    const docHeight = window.innerHeight;

    document.getElementsByTagName('body')[0].style.minHeight = docHeight + 'px';
  }

  window.addEventListener('resize', onResize);
  onResize();
}

function setupCopyrightDates() {
  d3.select('[data-copyright-date]').text(() => (new Date()).getFullYear());
}

adjustBodyHeight();
setupCopyrightDates();